import React, { Component } from 'react';

import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

class SelectInput extends Component {
  render() {
    const {
      id,
      value,
      onChange,
      isInvalid,
      required,
      errorMessage,
      name,
      options,
      disabled = false,
    } = this.props;

    const attributes = {
      id,
      value,
      onChange,
      isInvalid,
      required,
      name,
      disabled,
    };
    return (
      <Form.Group className='form-input-group sm-mb10 xs-mb10'>
        <Form.Select
          size='md'
          className='font-family-secondary'
          {...attributes}
        >
          <option value='' disabled>
            Please select an option
          </option>
          {options.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Form.Select>
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
}

SelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default SelectInput;
