import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { Component } from 'react';

import FormHeader from './formHeader';
import FormUtils from '../../utils/formUtils';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import MandatoryLabel from '../common/mandatoryLabel';
import PropTypes from 'prop-types';
import RadioInput from '../common/input/radioInput';
import SelectDay from '../common/input/SelectDay';
import SelectInput from '../common/input/selectInput';
import SelectMonth from '../common/input/selectMonth';
import SelectYear from '../common/input/selectYear';
import SidebarLayout from '../layout/sidebarLayout';
import { isEmpty } from 'lodash';

class Step2 extends Component {
  constructor(props) {
    super(props);
  }

  getSelectInput = (array) => {
    const temp = [];
    if (array)
      array.forEach((e) => {
        temp.push({
          label: e.name,
          value: e.fips,
        });
      });
    return temp;
  };

  state = {
    county: this.props.counties.length === 1 ? this.props.counties[0].fips : '',
    day: '',
    month: '',
    year: '',
    gender: '',
    viewPartnerRates: '',
    spouseGender: '',
    spouseDay: '',
    spouseYear: '',
    spouseMonth: '',
    availableCounties: this.getSelectInput(this.props.counties),
    validated: false,
  };

  onCountyChange = (event) => this.setState({ county: event.target.value });

  onDayChange = (day) => this.setState({ day });

  onMonthChange = (month) => this.setState({ month });

  onYearChange = (year) => this.setState({ year });

  onSpouseDayChange = (day) => this.setState({ spouseDay: day });

  onSpouseMonthChange = (month) => this.setState({ spouseMonth: month });

  onSpouseYearChange = (year) => this.setState({ spouseYear: year });

  onGenderChange = (value) => this.setState({ gender: value });

  onSpouseGenderChange = (value) => this.setState({ spouseGender: value });

  onHouseholdDiscountChange = (value) =>
    this.setState({ viewPartnerRates: value });

  onFormSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ validated: true });
      FormUtils.scrollToTop();
      event.stopPropagation();
    } else {
      const {
        day,
        month,
        year,
        county,
        gender,
        viewPartnerRates,
        spouseDay,
        spouseMonth,
        spouseYear,
        spouseGender,
      } = this.state;
      const { counties } = this.props;
      const data = {
        county: counties.length === 1 ? counties[0].fips : county,
        day,
        month,
        year,
        gender: gender,
        isPartnerSelected: viewPartnerRates,
      };
      if (viewPartnerRates === 'yes') {
        data.partnerInfo = {
          day: spouseDay,
          month: spouseMonth,
          year: spouseYear,
          gender: spouseGender,
        };
      }
      this.props.onSubmit(data);
    }
  };

  isFormSubmitDisabled = () => {
    const {
      day,
      month,
      year,
      gender,
      viewPartnerRates,
      spouseMonth,
      spouseDay,
      spouseYear,
      spouseGender,
      county,
    } = this.state;
    if (
      isEmpty(day) ||
      isEmpty(month) ||
      isEmpty(year) ||
      isEmpty(gender) ||
      isEmpty(viewPartnerRates) ||
      isEmpty(county)
    ) {
      return true;
    } else if (
      viewPartnerRates === 'yes' &&
      (isEmpty(spouseDay) ||
        isEmpty(spouseMonth) ||
        isEmpty(spouseYear) ||
        isEmpty(spouseGender))
    ) {
      return true;
    } else return false;
  };

  render() {
    const {
      viewPartnerRates,
      spouseGender,
      availableCounties,
      gender,
      day,
      month,
      year,
      spouseDay,
      spouseMonth,
      spouseYear,
      validated,
      county,
    } = this.state;

    const { isMedicareAdvantage } = this.props;

    return (
      <SidebarLayout securityIcons>
        <FormHeader text="We're Almost There" />
        <Form
          id='step-2-form'
          noValidate
          validated={validated}
          onSubmit={this.onFormSubmit}
        >
          <Row>
            <Col className='mc-form-input' xs={12}>
              <p className='form-input-label'>
                County
                <MandatoryLabel />
              </p>
              {/* <DateInput
                onChange={this.onDateOfBirthChange}
                id='date-of-birth'
                name='date-of-birth'
                placeholder='Date of Birth'
              /> */}
              <Row>
                <Col xs={12}>
                  <SelectInput
                    disabled={availableCounties.length === 1}
                    options={availableCounties}
                    id='county'
                    onChange={this.onCountyChange}
                    name='county'
                    required={true}
                    errorMessage='Please select a county'
                    value={county}
                  />
                </Col>
              </Row>
            </Col>
            <Col className='mc-form-input' xs={12}>
              <p className='form-input-label'>
                Date of Birth
                {!isMedicareAdvantage && <MandatoryLabel />}
              </p>
              {/* <DateInput
                onChange={this.onDateOfBirthChange}
                id='date-of-birth'
                name='date-of-birth'
                placeholder='Date of Birth'
              /> */}
              <Row>
                <Col xs={12} sm={4}>
                  <SelectMonth
                    onChange={this.onMonthChange}
                    data={month}
                    required={isMedicareAdvantage ? false : true}
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <SelectDay
                    onChange={this.onDayChange}
                    data={day}
                    required={isMedicareAdvantage ? false : true}
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <SelectYear
                    onChange={this.onYearChange}
                    data={year}
                    required={isMedicareAdvantage ? false : true}
                  />
                </Col>
              </Row>
            </Col>
            <Col className='mc-form-input' xs={12}>
              <p className='form-input-label'>
                Gender
                {!isMedicareAdvantage && <MandatoryLabel />}
              </p>
              <RadioInput
                options={[
                  {
                    id: 'male',
                    label: 'Male',
                    value: 'male',
                  },
                  {
                    id: 'female',
                    label: 'Female',
                    value: 'female',
                  },
                ]}
                id='gender'
                name='gender'
                onChange={this.onGenderChange}
                value={gender}
                required={isMedicareAdvantage ? false : true}
                validated={validated}
                errorMessage={'Please select an option'}
              />
            </Col>
            <Col className='mc-form-input' xs={12}>
              <p className='form-input-label'>
                Household discounts up to 15% may be available for each member.
                Would you like to view rates for a Spouse/Partner?
                <MandatoryLabel />
              </p>
              <RadioInput
                options={[
                  {
                    id: 'yes',
                    label: 'Yes',
                    value: 'yes',
                  },
                  {
                    id: 'no',
                    label: 'No',
                    value: 'no',
                  },
                ]}
                id='rates-for-spouse'
                name='rates-for-spouse'
                onChange={this.onHouseholdDiscountChange}
                value={viewPartnerRates}
                required={true}
                validated={validated}
                errorMessage={'Please select an option'}
              />
            </Col>

            {viewPartnerRates === 'yes' && (
              <>
                <Col className='mc-form-input' xs={12}>
                  <p className='form-input-label'>
                    Spouse/Partner Date of Birth
                    {!isMedicareAdvantage && <MandatoryLabel />}
                  </p>
                  {/* <DateInput
                    onChange={this.onSpouseDateOfBirthChange}
                    id='spouse-date-of-birth'
                    name='spouse-date-of-birth'
                    placeholder='Spouse Date of Birth'
                  /> */}
                  <Row>
                    <Col xs={12} sm={4}>
                      <SelectMonth
                        onChange={this.onSpouseMonthChange}
                        idPrefix='spouse'
                        data={spouseMonth}
                        required={isMedicareAdvantage ? false : true}
                      />
                    </Col>
                    <Col xs={12} sm={4}>
                      <SelectDay
                        onChange={this.onSpouseDayChange}
                        idPrefix='spouse'
                        data={spouseDay}
                        required={isMedicareAdvantage ? false : true}
                      />
                    </Col>
                    <Col xs={12} sm={4}>
                      <SelectYear
                        onChange={this.onSpouseYearChange}
                        idPrefix='spouse'
                        data={spouseYear}
                        required={isMedicareAdvantage ? false : true}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className='mc-form-input' xs={12}>
                  <p className='form-input-label'>
                    Spouse/Partner Gender
                    {!isMedicareAdvantage && <MandatoryLabel />}
                  </p>
                  <RadioInput
                    options={[
                      {
                        id: 'male',
                        label: 'Male',
                        value: 'male',
                      },
                      {
                        id: 'female',
                        label: 'Female',
                        value: 'female',
                      },
                    ]}
                    id='spouse-gender'
                    name='spouse-gender'
                    onChange={this.onSpouseGenderChange}
                    value={spouseGender}
                    required={isMedicareAdvantage ? false : true}
                    validated={validated}
                    errorMessage={'Please select an option'}
                  />
                </Col>
              </>
            )}
            <Col xs={12} md={6} lg={5} className='mc-form-input'>
              <Button
                className='btn btn-primary-custom w-100 mt20'
                type='submit'
                id='step-2-submit-btn'
                // disabled={this.isFormSubmitDisabled()}
              >
                <span>
                  Final Step
                  <HiOutlineArrowNarrowRight
                    color='white'
                    scale={3}
                    style={{ marginLeft: 10 }}
                  />
                </span>
              </Button>
            </Col>
          </Row>
        </Form>
      </SidebarLayout>
    );
  }
}

Step2.propTypes = {
  counties: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isMedicareAdvantage: PropTypes.bool.isRequired,
};

Step2.defaultProps = {
  isMedicareAdvantage: false,
};

export default Step2;
