import 'react-app-polyfill/ie11';

import React, { useEffect, useState } from 'react';

import Cookies from 'universal-cookie';
import FormService from '../../service/formService';
import FormUtils from '../../utils/formUtils';
import Layout from '../layout/layout';
import PageLoader from '../common/loading/pageLoader';
import PropTypes from 'prop-types';
import SentryService from '../../service/sentryService';
import Step2 from './step2';
import Step3 from './step3';
import ZipCodeService from '../../service/zipcodeService';
import { getSearchParams } from 'gatsby-query-params';
import { isEmpty } from 'lodash';
import { fireEvent } from '../../cra';

const cookies = new Cookies();
const maxAge = 365 * 24 * 60 * 60;

function NavigationComponent({ location }) {
  const [lead_id, setLead_id] = useState('');
  const [loading, setLoading] = useState(false);
  const [ap, setAP] = useState('');
  const [jlID, setJLID] = useState('');
  const qp = getSearchParams();

  const [callInNum, setCallInNum] = useState('');
  const [cookieValues, setCookieValues] = useState({
    hotjarSessionCookie: '',
    leadClientIDGoogleCookie: '',
    originalLeadAdSourceCookie: '',
    originalLeadMediumCookie: '',
    originalLeadAdCampaignCookie: '',
    originalLeadAdGroupCookie: '',
    originalLeadDateCookie: '',
    originalLeadURLCookie: '',
    originalGoogleClientIDCookie: '',
    originalLeadNetwork: '',
    originalLeadMatchType: '',
    originalLeadKeyword: '',
    originalLeadQueryString: '',
    originalLeadContent: '',
    originalLeadExtension: '',
    originalLeadDevice: '',
    originalLeadLocation: '',
    originalLeadPlacement: '',
    originalLeadAdPosition: '',
    originalLeadDeviceBrand: '',
    originalLeadDeviceName: '',
    originalLeadOsVersion: '',
    originalLeadScreenHeight: '',
    originalLeadScreenWidth: '',
    originalLeadLandingPage: '',
    originalLeadReferringUrl: '',
    originalLeadIPAddress: '',
    originalLeadBrowser: '',
    originalLeadOS: '',
    originalLeadCampaignID: '',
    originalLeadAdGroupID: '',
    originalLeadKeywordID: '',
    originalLeadExtensionID: '',
    leadBrand: '',
  });

  // The state will contain all the data from the form steps
  // Each step will update the fields that correspond to them
  const [state, setState] = useState({
    selectedComponent: undefined,
    county: '',
    stateAbbrv: '',
    day: '',
    month: '',
    year: '',
    gender: '',
    spouse: '',
    spouse_month: '',
    spouse_year: '',
    spouse_day: '',
    spouse_gender: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    sms_opt_in: 'false',
    // Image shown to user in homepage banner
    image_id: '',
    // available counties
    availableCounties: [],
  });

  // Navigate to landing page due to postal code error
  const navigateToLandingPage = () => {
    if (FormUtils.getDocument() && FormUtils.getDocument().referrer) {
      window.location.href = FormUtils.getDocument().referrer;
    } else {
      if (qp.isMedicareAdvantage)
        window.location.href = process.env.GATSBY.LEAD_APP_LANDING_REDIRECT
          ? process.env.GATSBY.LEAD_APP_LANDING_REDIRECT + '/ma'
          : 'rates.medicarecompared.com/ma';
      else
        window.location.href = process.env.GATSBY.LEAD_APP_LANDING_REDIRECT
          ? process.env.GATSBY.LEAD_APP_LANDING_REDIRECT + '/ms'
          : 'rates.medicarecompared.com/ms';
    }
  };

  // ComponentDidMount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const postalCode = params.get('postalCode');
    if (isEmpty(postalCode)) {
      navigateToLandingPage();
    }
    if (typeof window !== 'undefined') {
      window.onbeforeunload = function () {
        return 'Sure you want to leave?';
      };
    }
  }, []);

  var leadData = {
    postalCode: qp.postalCode,
    firstName: state.first_name,
    lastName: state.last_name,
    birthdate: state.year + '-' + state.month + '-' + state.day,
    gender: state.gender,
    email: state.email,
    phone: parseInt(FormUtils.cleansePhone(state.phone)),
    spouse: state.spouse,
    spouseGender: state.spouse_gender,
    spouseBirthdate:
      state.spouse_year + '-' + state.spouse_month + '-' + state.spouse_day,
    leadAdSource: qp.utm_source ? qp.utm_source.toString() : 'direct',
    leadMedium: qp.utm_medium ? qp.utm_medium.toString() : 'search',
    leadAdCampaign: qp.utm_campaign ? qp.utm_campaign.toString() : '',
    leadAdGroup: qp.adgroupid ? qp.adgroupid.toString() : '',
    leadKeyword: qp.utm_term ? qp.utm_term.toString() : '',
    leadNetwork: qp.network ? qp.network.toString() : '',
    leadAdPosition: qp.adposition ? qp.adposition.toString() : '',
    gclid: qp.gclid
      ? qp.gclid.toString()
      : qp.wbraid
      ? qp.wbraid.toString()
      : '',
    fbclid: qp.fbclid ? qp.fbclid.toString() : '',
    leadContent: qp.utm_content ? qp.utm_content.toString() : '',
    leadExtension: qp.extensionid ? qp.extensionid.toString() : '',
    leadMatchType: qp.matchtype ? qp.matchtype.toString() : '',
    leadLocation: qp.location ? qp.location.toString() : '',
    leadDevice: FormUtils.getDeviceDetails()
      ? FormUtils.getDeviceDetails().type
      : '',
    msclkid: qp.msclkid ? qp.msclkid.toString() : '',
    leadPlacement: qp.placement ? qp.placement.toString() : '',
    leadQueryString: qp.querystring ? qp.querystring.toString() : '',
    excludeFromBoberdooAPICallouts: qp.info ? qp.info.toString() : '',
    leadBrowser: FormUtils.getBrowserName(),
    hotjarSession: qp.hotjarSession
      ? qp.hotjarSession
      : cookieValues.hotjarSessionCookie,
    leadClientIDGoogle: qp.leadClientIDGoogle
      ? qp.leadClientIDGoogle
      : cookieValues.leadClientIDGoogleCookie,
    activeProspect: ap,
    journayaLeadID: jlID,
    leadStatus: 'New',
    leadSubStatus: '',
    leadPageID: qp.leadPageID,
    smsOptIn: state.sms_opt_in,
    osName: FormUtils.getOS(),
    sampleQuote: '',
    matokenID: qp.MATOKENID ? qp.MATOKENID.toString() : '',
    sessionStartTime: qp.sessionStartTime
      ? qp.sessionStartTime
      : new Date().toISOString(),

    leadDeviceBrand: FormUtils.getDeviceDetails()
      ? FormUtils.getDeviceDetails().brand
      : '',
    leadDeviceName: FormUtils.getDeviceDetails()
      ? FormUtils.getDeviceDetails().model
      : '',
    leadOsVersion: FormUtils.getOSVersion(),
    leadScreenHeight: FormUtils.getWindow()
      ? FormUtils.getWindow().outerHeight
      : '',
    leadScreenWidth: FormUtils.getWindow()
      ? FormUtils.getWindow().outerWidth
      : '',
    leadLandingPage: FormUtils.getDocument()
      ? FormUtils.getDocument().location.origin +
        FormUtils.getDocument().location.pathname
      : '',
    leadReferringUrl:
      FormUtils.getDocument() && FormUtils.getDocument().referrer
        ? new URL(FormUtils.getDocument().referrer).hostname
        : '',
    campaignID: qp.campaignID ? qp.campaignID.toString() : '',
    adgroup_ID: qp.adgroup_ID ? qp.adgroup_ID.toString() : '',
    keywordID: qp.keywordID ? qp.keywordID.toString() : '',
    extensionID: qp.extensionid ? qp.extensionid.toString() : '',

    originalLeadAdSource: cookieValues.originalLeadAdSourceCookie,
    originalLeadMedium: cookieValues.originalLeadMediumCookie,
    originalLeadAdCampaign: cookieValues.originalLeadAdCampaignCookie,
    originalLeadAdGroup: cookieValues.originalLeadAdGroupCookie,
    originalLeadDate: cookieValues.originalLeadDateCookie,
    originalLeadURL: cookieValues.originalLeadURLCookie,
    originalGoogleClientID: cookieValues.originalGoogleClientIDCookie,

    originalLeadNetwork: cookieValues.originalLeadNetwork,
    originalLeadMatchType: cookieValues.originalLeadMatchType,
    originalLeadKeyword: cookieValues.originalLeadKeyword,
    originalLeadQueryString: cookieValues.originalLeadQueryString,
    originalLeadContent: cookieValues.originalLeadContent,
    originalLeadExtension: cookieValues.originalLeadExtension,
    originalLeadDevice: cookieValues.originalLeadDevice,
    originalLeadLocation: cookieValues.originalLeadLocation,
    originalLeadPlacement: cookieValues.originalLeadPlacement,
    originalLeadAdPosition: cookieValues.originalLeadAdPosition,
    originalLeadDeviceBrand: cookieValues.originalLeadDeviceBrand,
    originalLeadDeviceName: cookieValues.originalLeadDeviceName,
    originalLeadOsVersion: cookieValues.originalLeadOsVersion,
    originalLeadScreenHeight: cookieValues.originalLeadScreenHeight,
    originalLeadScreenWidth: cookieValues.originalLeadScreenWidth,
    originalLeadLandingPage: cookieValues.originalLeadLandingPage,
    originalLeadReferringUrl: cookieValues.originalLeadReferringUrl,
    originalLeadIPAddress: cookieValues.originalLeadIPAddress,
    originalLeadBrowser: cookieValues.originalLeadBrowser,
    originalLeadOS: cookieValues.originalLeadOS,
    originalLeadCampaignID: cookieValues.originalLeadCampaignID,
    originalLeadAdGroupID: cookieValues.originalLeadAdGroupID,
    originalLeadKeywordID: cookieValues.originalLeadKeywordID,
    originalLeadExtensionID: cookieValues.originalLeadExtensionID,
    // Image shown to user in banner
    term_id: qp.termId ? qp.termId.toString() : '',
    leadBrand: qp.leadBrand ? qp.leadBrand.toString() : '',
  };

  const setStep2Details = (data) => {
    setState((prevState) => ({
      ...prevState,
      day: data.day,
      month: data.month,
      year: data.year,
      gender: data.gender,
      spouse: data.isPartnerSelected,
      spouse_gender: data.partnerInfo ? data.partnerInfo.gender : '',
      spouse_day: data.partnerInfo ? data.partnerInfo.day : '',
      spouse_month: data.partnerInfo ? data.partnerInfo.month : '',
      spouse_year: data.partnerInfo ? data.partnerInfo.year : '',
      county: data.county ? data.county : '',
      selectedComponent: 'Step3',
    }));
    FormUtils.scrollToTop();
  };

  const setStep3Details = (data) => {
    setState((prevState) => ({
      ...prevState,
      ...data,
      selectedComponent: 'SecondaryForm',
    }));
    FormUtils.scrollToTop();
    FormUtils.getWindow().onbeforeunload = null;
  };

  const renderInternalComponent = () => {
    if (loading || isEmpty(state.availableCounties)) return <PageLoader />;
    else
      switch (state.selectedComponent) {
        case 'Step3':
        case 'SecondaryForm':
          return <Step3 onSubmit={setStep3Details} />;

        default:
          return (
            <Step2
              isMedicareAdvantage={qp.isMedicareAdvantage}
              onSubmit={setStep2Details}
              counties={state.availableCounties}
            />
          );
      }
  };

  // Internal Methods Start
  const checkAP = () => {
    const apValue =
      cookies.get('trustedFormId') !== undefined
        ? cookies.get('trustedFormId')
        : '';
    console.log('AP Setting value :: ', apValue);
    setAP(apValue);
  };

  const checkLeadiD = () => {
    const liD =
      cookies.get('externalLeadiD') !== undefined
        ? cookies.get('externalLeadiD')
        : '';
    console.log('External Lead ID Setting value :: ', liD);
    setJLID(liD);
    if (typeof window !== 'undefined' && window.LeadiD) {
      window.LeadiD.snap();
    }
  };

  const getLeadURL = () => {
    if (typeof window !== 'undefined') {
      return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        window.location.pathname
      );
    } else {
      return '';
    }
  };

  const createSalesforceUser = () => {
    setLoading(true);
    // Fields for sending email
    leadData.subject = `Lead App - Main Form Submission - ${leadData.leadBrand}`;
    leadData.fullURL = FormUtils.getURL();

    // Fields for SF Entry
    leadData.selectedChoice = createPlanString();
    leadData.leadSource = getLeadSource();
    leadData.leadURL = getLeadURL();
    leadData.redirectURL = /sunfirematrix/.test(decideUrl()) ? decideUrl() : '';
    leadData.sampleQuote = encodedData();
    leadData.leadIPAddress =
      cookies.get('lead_IP-Address') !== undefined
        ? cookies.get('lead_IP-Address')
        : '';
    leadData.mainFormReceived = true;
    FormService.createLeadForRates(leadData)
      .then(async function (data) {
        cookies.set('main_Form-Recieved', true, {
          path: '/',
          domain: '.medicarecompared.com',
          encode: unescape,
        });
        leadData.leadID = data.data;
        setLead_id((lead_id) => lead_id + data.data);
      })
      .catch(function (error) {
        fireEvent(
          'navigate',
          {
            target: { href: '/thanks' },
          },
          {
            description: 'MC Lead Submission Failure',
          }
        );
        window.location.href = '/thanks';
        SentryService.publishError(error, 'CreateSalesforceUser');
        console.error('Something went wrong with the lead: ', error);
      });
  };

  const createPlanString = () => {
    let planString = 'Medicare Supplement (Medigap)';
    if (qp.isMedicareAdvantage)
      planString = 'Medicare Advantage (HMO, PPO, PFFS)';
    else if (qp.isPDP) planString = 'Prescription Drugs (Part D)';
    return planString;
  };

  const decideUrl = () => {
    let spouseOfAge = false;
    let hasSpouse = state.spouse === 'yes' ? true : false;
    let ofAge =
      FormUtils.getAge(state.year + '/' + state.month + '/' + state.day) >= 64;
    if (hasSpouse) {
      spouseOfAge =
        FormUtils.getAge(
          state.spouse_year + '/' + state.spouse_month + '/' + state.spouse_day
        ) >= 64;
    }
    if (!ofAge && (!hasSpouse || !spouseOfAge)) {
      return `https://www.sunfirematrix.com/app/consumer/yourmedicare/16423979/?defaultPlanType=MAPD#/plans/${qp.postalCode}/${state.county}/MAPD`;
    } else {
      const query = `?spouse=${
        state.spouse
      }&lead_id=${lead_id}&callinnum=${callInNum}&zip=${qp.postalCode}&state=${
        state.stateAbbrv
      }&gender=${state.gender.charAt(0).toUpperCase()}&age=${FormUtils.getAge(
        state.year + '/' + state.month + '/' + state.day
      )}`;
      const encodedQuery = `?info=${btoa(query)}`;
      return '/thanks' + encodedQuery;
    }
  };

  const encodedData = () => {
    const query = `callinnum=${callInNum}&zip=${
      qp.postalCode
    }&age=${FormUtils.getAge(
      state.year + '/' + state.month + '/' + state.day
    )}&gender=${state.gender.charAt(0).toUpperCase()}&state=${
      state.stateAbbrv
    }`;
    return `${btoa(query)}`;
  };

  const getLeadSource = () => {
    if (qp.utm_source === 'social' || qp.utm_source === 'fb') {
      return 'Facebook - Online';
    } else if (qp.utm_source === 'excelimpact' || qp.utm_source === 'apollo') {
      return 'Partner - Online';
    } else if (
      (leadData.email && leadData.email.includes('@test.com')) ||
      (leadData.lastName && leadData.lastName.toLowerCase() === 'test')
    ) {
      return 'Test';
    } else {
      return 'MedicareFAQ - Online';
    }
  };

  const saveCookieData = () => {
    setCookieValues({
      originalLeadAdSourceCookie:
        cookies.get('marketing_Original-Lead-Ad-Source') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Source')
          : '',
      originalLeadMediumCookie:
        cookies.get('marketing_Original-Lead-Medium') !== undefined
          ? cookies.get('marketing_Original-Lead-Medium')
          : '',
      originalLeadAdCampaignCookie:
        cookies.get('marketing_Original-Lead-Ad-Campaign') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Campaign')
          : '',
      originalLeadAdGroupCookie:
        cookies.get('marketing_Original-Lead-Ad-Group') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Group')
          : '',
      originalLeadDateCookie:
        cookies.get('marketing_Original-Lead-Date') !== undefined
          ? cookies.get('marketing_Original-Lead-Date')
          : '',
      originalLeadURLCookie:
        cookies.get('marketing_Original-Lead-URL') !== undefined
          ? cookies.get('marketing_Original-Lead-URL')
          : '',
      originalGoogleClientIDCookie:
        cookies.get('marketing_Original-Google-Client-ID') !== undefined
          ? cookies.get('marketing_Original-Google-Client-ID')
          : '',
      originalLeadNetwork:
        cookies.get('marketing_Original-Lead-Network') !== undefined
          ? cookies.get('marketing_Original-Lead-Network')
          : '',
      originalLeadMatchType:
        cookies.get('marketing_Original-Lead-Match-Type') !== undefined
          ? cookies.get('marketing_Original-Lead-Match-Type')
          : '',
      originalLeadKeyword:
        cookies.get('marketing_Original-Lead-Keywords') !== undefined
          ? cookies.get('marketing_Original-Lead-Keywords')
          : '',
      originalLeadQueryString:
        cookies.get('marketing_Original-Lead-Query-String') !== undefined
          ? cookies.get('marketing_Original-Lead-Query-String')
          : '',
      originalLeadContent:
        cookies.get('marketing_Original-Lead-Content') !== undefined
          ? cookies.get('marketing_Original-Lead-Content')
          : '',
      originalLeadExtension:
        cookies.get('marketing_Original-Lead-Extension') !== undefined
          ? cookies.get('marketing_Original-Lead-Extension')
          : '',
      originalLeadDevice:
        cookies.get('marketing_Original-Lead-Device') !== undefined
          ? cookies.get('marketing_Original-Lead-Device')
          : '',
      originalLeadLocation:
        cookies.get('marketing_Original-Lead-Location') !== undefined
          ? cookies.get('marketing_Original-Lead-Location')
          : '',
      originalLeadPlacement:
        cookies.get('marketing_Original-Lead-Placement') !== undefined
          ? cookies.get('marketing_Original-Lead-Placement')
          : '',
      originalLeadAdPosition:
        cookies.get('marketing_Original-Lead-Ad-Position') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Position')
          : '',
      originalLeadDeviceName:
        cookies.get('marketing_Original-Lead-Device-Name') !== undefined
          ? cookies.get('marketing_Original-Lead-Device-Name')
          : '',
      originalLeadDeviceBrand:
        cookies.get('marketing_Original-Lead-Device-Brand') !== undefined
          ? cookies.get('marketing_Original-Lead-Device-Brand')
          : '',
      originalLeadOsVersion:
        cookies.get('marketing_Original-Lead-Lead-OS-Version') !== undefined
          ? cookies.get('marketing_Original-Lead-Lead-OS-Version')
          : '',
      originalLeadScreenHeight:
        cookies.get('marketing_Original-Lead-Screen-Height') !== undefined
          ? cookies.get('marketing_Original-Lead-Screen-Height')
          : '',
      originalLeadScreenWidth:
        cookies.get('marketing_Original-Lead-Screen-Width') !== undefined
          ? cookies.get('marketing_Original-Lead-Screen-Width')
          : '',
      originalLeadLandingPage:
        cookies.get('marketing_Original-Lead-Landing-Page') !== undefined
          ? cookies.get('marketing_Original-Lead-Landing-Page')
          : '',
      originalLeadReferringUrl:
        cookies.get('marketing_Original-Lead-Referring-URL') !== undefined
          ? cookies.get('marketing_Original-Lead-Referring-URL')
          : '',
      originalLeadIPAddress:
        cookies.get('marketing_Original-Lead-IP-Address') !== undefined
          ? cookies.get('marketing_Original-Lead-IP-Address')
          : '',
      originalLeadBrowser:
        cookies.get('marketing_Original-Lead-Lead-Browser') !== undefined
          ? cookies.get('marketing_Original-Lead-Lead-Browser')
          : '',
      originalLeadOS:
        cookies.get('marketing_Original-Lead-Operating-System') !== undefined
          ? cookies.get('marketing_Original-Lead-Operating-System')
          : '',
      originalLeadCampaignID:
        cookies.get('marketing_Original-Lead-Campaign-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Campaign-ID')
          : '',
      originalLeadAdGroupID:
        cookies.get('marketing_Original-Lead-Ad-Group-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Group-ID')
          : '',
      originalLeadKeywordID:
        cookies.get('marketing_Original-Lead-Keyword-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Keyword-ID')
          : '',
      originalLeadExtensionID:
        cookies.get('marketing_Original-Lead-Extension-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Extension-ID')
          : '',
      leadBrand:
        cookies.get('leadBrand') !== undefined
          ? cookies.get('leadBrand')
          : 'medicare compared',
    });
  };

  const createCookies = (publicIPAddr) => {
    let device = FormUtils.getDeviceDetect();
    if (!('marketing_Original-Lead-Date' in cookies.getAll())) {
      cookies.set(
        'marketing_Original-Lead-Ad-Source',
        qp.utm_source ? qp.utm_source.toString() : 'direct',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Medium',
        qp.utm_medium ? qp.utm_medium.toString() : 'search',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Ad-Campaign',
        qp.utm_campaign ? qp.utm_campaign.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Ad-Group',
        qp.adgroupid ? qp.adgroupid.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Date', new Date().toISOString(), {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-URL', getLeadURL(), {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Google-Client-ID',
        cookies.get('_ga') ? cookies.get('_ga') : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
    }
    // * Cookies required for Invoca
    // * Additional check needed to set these values for people who already have visited rates but not have these values filled
    if (!('marketing_Original-Lead-Network' in cookies.getAll())) {
      cookies.set('marketing_Original-Lead-Network', leadData.leadNetwork, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Match-Type',
        leadData.leadMatchType,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Keywords', leadData.leadKeyword, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Query-String',
        leadData.leadQueryString,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Content', leadData.leadContent, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-Extension', leadData.leadExtension, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-Device', leadData.leadDevice, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-Location', leadData.leadLocation, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-Placement', leadData.leadPlacement, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Ad-Position',
        leadData.leadAdPosition,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Device-Name',
        FormUtils.getDeviceDetails() ? FormUtils.getDeviceDetails().model : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Device-Brand',
        FormUtils.getDeviceDetails() ? FormUtils.getDeviceDetails().brand : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Lead-OS-Version', device.osVersion, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Screen-Height',
        FormUtils.getWindow() ? FormUtils.getWindow().outerHeight : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Screen-Width',
        FormUtils.getWindow() ? FormUtils.getWindow().outerWidth : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Landing-Page',
        FormUtils.getDocument()
          ? FormUtils.getDocument().location.origin +
              FormUtils.getDocument().location.pathname
          : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: encodeURI,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Referring-URL',
        FormUtils.getDocument() && FormUtils.getDocument().referrer
          ? new URL(FormUtils.getDocument().referrer).hostname
          : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: encodeURI,
        }
      );
      cookies.set('marketing_Original-Lead-IP-Address', publicIPAddr, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Lead-Browser',
        leadData.leadBrowser,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Operating-System', leadData.osName, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarecompared.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Campaign-ID',
        qp.campaignID ? qp.campaignID.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Ad-Group-ID',
        qp.adgroup_ID ? qp.adgroup_ID.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Keyword-ID',
        qp.keywordID ? qp.keywordID.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Extension-ID',
        qp.extensionid ? qp.extensionid.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarecompared.com',
          encode: unescape,
        }
      );
    }
  };

  const createCookiesForInvoca = (publicIPAddr) => {
    cookies.set('lead_source', getLeadSource(), {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Source', leadData.leadAdSource, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Medium', leadData.leadMedium, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Campaign', leadData.leadAdCampaign, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Group', leadData.leadAdGroup, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Network', leadData.leadNetwork, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Match-Type', leadData.leadMatchType, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Keyword', leadData.leadKeyword, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Query-String', leadData.leadQueryString, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Content', leadData.leadContent, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Extension', leadData.leadExtension, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Device', leadData.leadDevice, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Location', leadData.leadLocation, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Placement', leadData.leadPlacement, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Position', leadData.leadAdPosition, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Client-ID-Google', leadData.leadClientIDGoogle, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Device-Brand', leadData.leadDeviceBrand, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Device-Name', leadData.leadDeviceName, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_OS-Version', leadData.leadOsVersion, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Screen-Height', leadData.leadScreenHeight, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Screen-Width', leadData.leadScreenWidth, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Landing-Page', leadData.leadLandingPage, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: encodeURI,
    });
    cookies.set('lead_URL', getLeadURL(), {
      path: '/',
      domain: '.medicarecompared.com',
      encode: encodeURI,
    });
    cookies.set('lead_Referring-URL', leadData.leadReferringUrl, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: encodeURI,
    });
    cookies.set('lead_IP-Address', publicIPAddr, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Browser', leadData.leadBrowser, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Operating-System', leadData.osName, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('google_Click-ID', leadData.gclid, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('ms_Click-ID', leadData.msclkid, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('fc_Click-ID', leadData.fbclid, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('main_Form-Recieved', false, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('sup-Form-Received', false, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('active_Prospect', leadData.activeProspect, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('jornaya-Lead-ID', leadData.journayaLeadID, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Campaign-ID', leadData.campaignID, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Group-ID', leadData.adgroup_ID, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Keyword-ID', leadData.keywordID, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set('lead_Extension-ID', leadData.extensionID, {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
    cookies.set(
      'marketing_Phone-Ad-Type',
      leadData.leadAdSource + ' - ' + leadData.leadMedium,
      { path: '/', domain: '.medicarecompared.com', encode: unescape }
    );
    cookies.set('leadBrand', 'medicare compared', {
      path: '/',
      domain: '.medicarecompared.com',
      encode: unescape,
    });
  };

  useEffect(() => {
    if (state.selectedComponent === 'Step3') {
      saveCookieData();
      checkAP();
      checkLeadiD();
      let callInNum = document.getElementById('callInNum');
      if (callInNum) setCallInNum(callInNum.text);
    } else if (state.selectedComponent === 'SecondaryForm') {
      cookies.set('leadphone', state.phone.match(/\d/g).join(''), {
        path: '/',
        encode: unescape,
      });
      FormService.fireLeadConversionEvent();
      createSalesforceUser();
    }
    return;
  }, [state]);

  useEffect(() => {
    console.log('QP Effect called');
    // Check for Counties from ZipCode
    if (
      !isEmpty(qp.postalCode) &&
      isEmpty(state.availableCounties) &&
      !loading
    ) {
      setLoading(true);
      ZipCodeService.getCountyForZip(qp.postalCode)
        .then((response) => {
          if (response.zipcodes && response.zipcodes.length > 0) {
            let countyObjects = [
              {
                name: response.zipcodes[0].county_name,
                fips: response.zipcodes[0].county_fips,
              },
            ];
            if (response.zipcodes[0].alternate_counties) {
              response.zipcodes[0].alternate_counties.forEach((county) => {
                countyObjects.push({
                  name: county.county_name,
                  fips: county.county_fips,
                });
              });
            }

            setState({
              ...state,
              availableCounties: countyObjects,
              stateAbbrv: response.city_states[0].state_abbreviation,
            });
            setLoading(false);
            createCookies();
            createCookiesForInvoca();
          } else {
            console.log('Error => ', response);
            throw response;
          }
        })
        .catch((err) => {
          // Publish error to Sentry to track
          console.log('Error with Zipcode :: ', err);
          navigateToLandingPage();
        });
    } else {
      // Postal code null or not able to fetch available counties
      console.log('Postal Code is possibly wrong/null');
    }
  }, [qp]);

  useEffect(() => {
    if (state.selectedComponent !== 'SecondaryForm') return;
    console.log('Redirection starting', lead_id);
    const redirectURL = decideUrl();
    fireEvent(
      'navigate',
      {
        target: { href: redirectURL },
      },
      {
        description: 'MC Form Submission',
      }
    );
    if (redirectURL.startsWith('https') || redirectURL.startsWith('http'))
      window.location.href = redirectURL;
    else window.location.href = redirectURL;
  }, [lead_id]);

  // Internal Methods End
  return (
    <Layout
      header={!loading && !isEmpty(state.availableCounties)}
      footer={!loading && !isEmpty(state.availableCounties)}
      stickyFooter={isEmpty(state.selectedComponent)}
    >
      {renderInternalComponent(state)}
    </Layout>
  );
}

NavigationComponent.propTypes = {
  location: PropTypes.object,
};

export default NavigationComponent;
