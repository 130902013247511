import NavigationComponent from '../component/forms/navigation';
import PropTypes from 'prop-types';
import React from 'react';

const IndexPage = ({ location }) => {
  return <NavigationComponent location={location} />;
};

IndexPage.propTypes = {
  location: PropTypes.object,
};

export default IndexPage;
