import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

class SelectDay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDay: props.data ? props.data : '',
    };
  }

  getDay = () => {
    return this.state.selectedDay;
  };

  onDayChange = (event) => {
    this.setState({
      selectedDay: event.target.value,
    });
    this.props.onChange && this.props.onChange(event.target.value);
  };

  render() {
    const { selectedDay } = this.state;
    const { required, idPrefix } = this.props;
    return (
      <>
        <Form.Group className='form-input-group sm-mb10 xs-mb10'>
          <Form.Select
            id={idPrefix ? `${idPrefix}-day` : 'day'}
            size='md'
            className='font-family-secondary'
            onChange={this.onDayChange}
            value={selectedDay}
            required={required}
          >
            <option disabled value=''>
              Day
            </option>
            {Array.from({ length: 31 }, (_, i) => i + 1).map((value, index) => {
              return (
                <option value={value} key={index}>
                  {value}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type='invalid'>
            Please select a date
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  }
}

SelectDay.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.string,
  idPrefix: PropTypes.string,
  required: PropTypes.bool,
};

export default SelectDay;
