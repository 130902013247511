import * as Sentry from '@sentry/gatsby';

const SentryService = {
  publishError: (error, functionName) => {
    Sentry.captureException(error, (scope) => {
      scope.setTag('functionCall', functionName);
      scope.setContext('details', {
        error: error.response.data,
        date: error.response.headers.date,
        statusText: error.response.statusText,
      });
      return scope;
    });
  },
};

export default SentryService;
