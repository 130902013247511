import { Button, Col, Form, Row } from 'react-bootstrap';

import FormHeader from './formHeader';
import FormUtils from '../../utils/formUtils';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import MandatoryLabel from '../common/mandatoryLabel';
import MaskedInput from 'react-maskedinput';
import PropTypes from 'prop-types';
import React from 'react';
import SidebarLayout from '../layout/sidebarLayout';
import UserService from '../../service/userService';

const phoneNumberPattern =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
const namePattern = /[a-zA-Z\s]{2,}/;
const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/;

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      sms_opt_in: false,
      validated: false,
      phoneExchangeType: '',
      validFirstName: false,
      validLastName: false,
      validEmail: false,
      validPhone: false,
    };
  }

  componentDidMount() {
    this.validateFirstName();
    this.validateLastName();
    this.validatePhoneNumber();
    this.validateEmail();
  }

  validateFirstName = (name) => {
    this.setState({
      validFirstName: namePattern.test(name),
    });
  };

  validateLastName = (name) => {
    this.setState({
      validLastName: namePattern.test(name),
    });
  };

  validatePhoneNumber = (phone) => {
    this.setState({ phoneExchangeType: '' });
    if (phoneNumberPattern.test(phone)) {
      UserService.validatePhoneNumber(phone)
        .then((response) => {
          if (
            response.status === 'invalid' ||
            response.status === 'bad_request'
          ) {
            throw response;
          } else {
            this.setState({
              validPhone: true,
              phoneExchangeType: response.exchange_type,
            });
          }
        })
        .catch(() => {
          this.setState({ phoneExchangeType: '', validPhone: false });
        });
    }
  };

  validateEmail = (email) => {
    this.setState({
      validEmail: emailPattern.test(email),
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const { validFirstName, validLastName, validEmail, validPhone } =
      this.state;

    if (
      form.checkValidity() === false ||
      !validFirstName ||
      !validLastName ||
      !validEmail ||
      !validPhone
    ) {
      FormUtils.scrollToTop();
      this.setState({ validated: true });
      event.stopPropagation();
    } else {
      const { first_name, last_name, email, phone, sms_opt_in } = this.state;
      const data = {
        first_name,
        last_name,
        email,
        phone,
        sms_opt_in: sms_opt_in ? 'true' : 'false',
      };

      this.props.onSubmit(data);
    }
  };

  render() {
    const {
      first_name,
      last_name,
      phone,
      email,
      validated,
      validFirstName,
      validLastName,
      validEmail,
      validPhone,
      phoneExchangeType,
    } = this.state;

    const { isMedicareAdvantage, isPDP } = this.props;

    return (
      <SidebarLayout 
        securityIcons 
        subFooter
        isMedicareAdvantage={isMedicareAdvantage}
        isPDP={isPDP}
      >
        <FormHeader text='Just One Final Step' />
        <Form
          id='step-3-form'
          noValidate
          onSubmit={this.onSubmit}
          validated={validated}
        >
          <Row>
            <Col xs={12} sm={6} className='mc-form-input'>
              <p className='form-input-label'>
                First Name
                <MandatoryLabel />
              </p>
              <Form.Group className='form-input-group sm-mb10 xs-mb20'>
                <Form.Control
                  autoComplete
                  id='first-name'
                  type='text'
                  placeholder='First Name'
                  value={first_name}
                  onChange={(event) => {
                    this.setState({
                      first_name: event.target.value,
                    });
                    this.validateFirstName(event.target.value);
                  }}
                  onBlur={(event) => {
                    this.setState({
                      first_name: FormUtils.cleanString(event.target.value),
                    });
                    this.validateFirstName(event.target.value);
                  }}
                  isInvalid={first_name !== '' && !validFirstName}
                  required
                  pattern='[a-zA-Z\s]{2,}'
                />
                <Form.Control.Feedback type='invalid'>
                  Please fill your first name
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} className='mc-form-input'>
              <p className='form-input-label'>
                Last Name
                <MandatoryLabel />
              </p>
              <Form.Group className='form-input-group sm-mb10 xs-mb20'>
                <Form.Control
                  id='last-name'
                  type='text'
                  placeholder='Last Name'
                  value={last_name}
                  onChange={(event) => {
                    this.setState({
                      last_name: event.target.value,
                    });
                    this.validateLastName(event.target.value);
                  }}
                  onBlur={(event) => {
                    this.setState({
                      last_name: FormUtils.cleanString(event.target.value),
                    });
                    this.validateLastName(event.target.value);
                  }}
                  isInvalid={last_name !== '' && !validLastName}
                  required
                  pattern='[a-zA-Z\s]{2,}'
                />
                <Form.Control.Feedback type='invalid'>
                  Please fill your last name
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} sm={6} className='mc-form-input'>
              <p className='form-input-label'>
                Phone Number
                <MandatoryLabel />
              </p>
              <Form.Group
                className='sm-mb10 xs-mb20 form-input-group'
                style={{ height: 50 }}
              >
                <Form.Control
                  id='phone'
                  as={MaskedInput}
                  mask='(111) 111-1111'
                  placeholder='(123) 456-7890'
                  type='tel'
                  value={phone}
                  onChange={(event) => {
                    this.setState({
                      phone: event.target.value,
                    });
                    this.validatePhoneNumber(event.target.value);
                  }}
                  required
                  pattern='^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$'
                  isInvalid={phone !== '' && !validPhone}
                />

                <Form.Control.Feedback type='invalid'>
                  Please provide a valid phone number
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} sm={12} className='mc-form-input'>
              <p className='form-input-label'>
                Email Address
                <MandatoryLabel />
              </p>
              <Form.Group className='form-input-group sm-mb10 xs-mb20'>
                <Form.Control
                  id='email'
                  type='email'
                  placeholder='ex: name@email.com'
                  onChange={(event) => {
                    this.setState({
                      email: event.target.value,
                    });
                    this.validateEmail(event.target.value);
                  }}
                  onBlur={(event) => {
                    this.setState({
                      email: FormUtils.cleanString(event.target.value),
                    });
                    this.validateEmail(event.target.value);
                  }}
                  isInvalid={email !== '' && !validEmail}
                  value={email}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please fill a valid email
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            {phoneExchangeType === 'cell' && (
              <Col xs={12}>
                <Form.Group className='sm-mb10 xs-mb20 mt20'>
                  <Form.Check
                    id='text-msg-checkbox'
                    inline
                    label='Yes, I want to receive text messages to review my personalized quote.'
                    name='receive_text'
                    type='checkbox'
                    onChange={(event) => {
                      this.setState({ sms_opt_in: event.target.checked });
                    }}
                  />
                </Form.Group>
              </Col>
            )}
            <Col xs={12} md={6} lg={5} className='mc-form-input'>
              <Button
                id='step-3-submit-btn'
                className='btn-primary-custom w-100 mt20'
                type='submit'
                // disabled={
                //   first_name === '' ||
                //   last_name === '' ||
                //   email === '' ||
                //   phone === '' ||
                //   !validFirstName ||
                //   !validLastName ||
                //   !validEmail ||
                //   !validPhone
                // }
              >
                <span>
                  See my plans
                  <HiOutlineArrowNarrowRight
                    color='white'
                    scale={3}
                    style={{ marginLeft: 10 }}
                  />
                </span>
              </Button>
            </Col>
          </Row>
        </Form>
      </SidebarLayout>
    );
  }
}

Step3.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isMedicareAdvantage: PropTypes.bool,
  isPDP: PropTypes.bool,
};
export default Step3;
