import axios from 'axios';
import axiosRetry from 'axios-retry';

const UserService = {
  validatePhoneNumber: (phoneNumber) => {
    return new Promise((resolve, reject) => {
      axiosRetry(axios, { retries: 3 });
      axios({
        method: 'post',
        url: '/api/verifyPhone',
        data: {
          phone: phoneNumber,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
        .then(function(data) {
          resolve(data.data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  getPublicIP: () => {
    axiosRetry(axios, { retries: 0 });
    return new Promise((resolve) => {
      axios
        .get(
          'https://api.ipgeolocation.io/ipgeo?apiKey=7bba8bbe515048ca8754094c7e945cc2'
        )
        .then((response) => {
          const publicIP_ = response?.data ? response.data.ip : '';
          resolve(publicIP_);
        })
        .catch(() => {
          console.error('Unable to get the public IP');
          resolve('');
        });
    });
  },
};

export default UserService;
